import { Injectable, inject } from '@angular/core';
import { map, of, type Observable } from 'rxjs';

import { HttpService } from '@clover/core/services/http.service';

import {
  ContactCompany,
  ContactSourceType,
  ContactType,
  type Contact,
  type ContactEmail,
  type ContactPhoneNumber,
} from './contacts.model';

interface ContactResponse {
  id: number;
  firstName: string;
  lastName: string;
  pictureUrl: string;
  emails: ContactEmailResponse[];
  phoneNumbers: ContactPhoneResponse[];
  companyId: number | undefined;
  companyName: string | undefined;
  companyLogoUrl: string | undefined;
  jobTitle: string;
  labels: string[];
  source: ContactSourceType;
  contactType: ContactType;
}

interface EmailContactResponse {
  email: string;
  sourceId: number;
  sourceType: ContactSourceType;
  firstName: string;
  lastName: string;
  fullName: string;
  logoUrl: string;
  title: string;
  companyId: number;
  companyName: string;
  companyLogo: string;
  externalAccountId: number;
  workspaceId: number;
  phoneNumbers: string[];
}

interface ContactEmailResponse {
  email: string;
  type: string | undefined;
}

interface ContactPhoneResponse {
  phoneNumber: string;
  type: string | undefined;
}

function mapContact(c: ContactResponse): Contact {
  return {
    id: c.id,
    name: `${c.firstName || ''} ${c.lastName || ''}`.trim(),
    avatarUrl: c.pictureUrl,
    emails: c.emails.map(mapContactEmail),
    phoneNumbers: c.phoneNumbers.map(mapContactPhone),
    company: mapContactCompany(c),
    source: c.source,
    type: c.contactType,
  };
}

function mapContactEmail(e: ContactEmailResponse): ContactEmail {
  return {
    email: e.email,
    type: e.type || undefined,
  };
}

function mapContactPhone(p: ContactPhoneResponse): ContactPhoneNumber {
  return {
    phoneNumber: p.phoneNumber,
    type: p.type || undefined,
  };
}

function mapContactCompany(c: ContactResponse): ContactCompany | undefined {
  if (c.contactType !== ContactType.CloverUser) return;
  if (!c.companyId) return;

  return {
    id: c.companyId,
    name: c.companyName,
    logoUrl: c.companyLogoUrl || undefined,
    title: c.jobTitle || undefined,
  };
}

function mapEmailContact(c: EmailContactResponse): Contact {
  return {
    id: -1,
    name: c.fullName,
    avatarUrl: c.logoUrl,
    emails: [{ email: c.email, type: undefined }],
    phoneNumbers: c.phoneNumbers.map((p) => ({ phoneNumber: p, type: undefined })),
    company: {
      id: c.companyId,
      name: c.companyName,
      logoUrl: c.companyLogo,
      title: c.title,
    },
    source: c.sourceType,
    type: ContactType.ExternalContact,
  };
}

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  private readonly http = inject(HttpService);

  getContactById(id: number, type?: ContactType): Observable<Contact | undefined> {
    if (!id) return of(undefined);

    return this.http
      .getV2<ContactResponse>(`/api/stream-conversations/contacts/${id}?type=${type || 'Unknown'}`)
      .pipe(map((c) => (c ? mapContact(c) : undefined)));
  }

  searchEmailContactsByQuery(query: string, limit: number): Observable<Contact[]> {
    return this.http
      .getV2<EmailContactResponse[]>(`/api/stream-conversations/my/email-contacts/search?query=${query}&limit=${limit}`)
      .pipe(map((response) => response.map(mapEmailContact)));
  }

  searchEmailContactsByEmails(emails: string[]): Observable<Contact[]> {
    return this.http
      .postV2<EmailContactResponse[]>(`/api/stream-conversations/my/email-contacts/search`, { emails })
      .pipe(map((response) => response.map(mapEmailContact)));
  }

  getContactByEmail(email: string): Observable<Contact | undefined> {
    return this.searchEmailContactsByEmails([email]).pipe(map((contacts) => contacts[0]));
  }
}
