<div class="task-product-card">
  <img
    [alt]="product.name"
    [src]="product.imageUrl || 'assets/svg/common/new-company-placeholder.svg'"
    class="task-product-card__image"
    height="96px"
    width="96px"
  />

  <div class="task-product-card__details">
    <div
      [ccTooltip]="product.name"
      [showOnlyOnOverflow]="true"
      [tooltipAlignment]="TooltipAlignment.Start"
      class="task-product-card__details-name"
    >
      {{ product.name }}
    </div>

    <div class="task-product-card__details-code">
      <div class="task-product-card__details-code-title">
        {{ 'conversations-v4.tasks.gtin' | translate }}
      </div>
      <div
        [ccTooltip]="product.gtin || product.itemNumber || ('common.strings.na' | translate)"
        [showOnlyOnOverflow]="true"
        [tooltipAlignment]="TooltipAlignment.Start"
        class="task-product-card__details-code-value"
      >
        {{ product.gtin || product.itemNumber || ('common.strings.na' | translate) }}
      </div>
    </div>
  </div>
</div>
