import { Clipboard } from '@angular/cdk/clipboard';
import { DialogModule } from '@angular/cdk/dialog';
import { ConnectedPosition, OverlayModule } from '@angular/cdk/overlay';
import { NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Injector,
  Input,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as R from 'ramda';
import { take } from 'rxjs/operators';

import { TaskPerformerSelectComponent } from '@conversations/tasks/task-performer-select/task-performer-select.component';
import { TaskProductCardComponent } from '@conversations/tasks/task-product-card/task-product-card.component';
import { TaskStatusChipComponent } from '@conversations/tasks/task-status-chip/task-status-chip.component';
import { TasksService } from '@conversations/tasks/tasks.service';
import { CoreModule } from '@core/core.module';
import { getOverlayVisibilityAfterOutsideClick } from '@core/helpers/get-overlay-visibility-after-outside-click';
import { DateFormatDistancePipe } from '@core/pipes/date-format.pipe';
import { CdkPortalService } from '@core/services/cdk-portal.service';
import { ConfigService } from '@core/services/config.service';
import { UserService } from '@core/services/user.service';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { presentConfirmationDialog } from '@design/overlays/confirmation-dialog/confirm';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownDividerComponent } from '@design/overlays/dropdown/dropdown-divider/dropdown-divider.component';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';
import { ToastType } from '@design/overlays/toast/toast';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

import { TaskAccessService } from '../task-access-notice-dialog/task-access.service';
import { Task, TaskEmailAccessLevel, TaskPreview, TaskRole, TaskStatus } from '../tasks.model';

export const getTaskAssignedAtLocale = (): Locale => {
  return {
    formatDistance: (token, count) => {
      const formatDistanceLocale = {
        xSeconds: '<1m',
        xMinutes: '{{count}}m',
        xHours: '{{count}}h',
        xDays: '{{count}}d',
        xMonths: '{{count}}mo',
        xYears: '{{count}}y',
      };
      return formatDistanceLocale[token].replace('{{count}}', count);
    },
  };
};

@UntilDestroy()
@Component({
  selector: 'cc-task-card',
  standalone: true,
  templateUrl: './task-card.component.html',
  styleUrls: ['./task-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CoreModule,
    TaskStatusChipComponent,
    DropdownComponent,
    OverlayModule,
    ButtonComponent,
    TooltipDirective,
    DropdownActionComponent,
    DropdownDividerComponent,
    RouterLink,
    DialogModule,
    NgOptimizedImage,
    TaskPerformerSelectComponent,
    TaskProductCardComponent,
    DateFormatDistancePipe,
    TranslateModule,
  ],
})
export class TaskCardComponent implements OnInit {
  @Input()
  task: TaskPreview;

  protected moreActionsDropdownVisible = false;
  protected moreActionsDropdownPositionStrategy: ConnectedPosition[] = [
    { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top', offsetX: 2, offsetY: 6 },
  ];

  protected readonly getTaskAssignedAtLocale = getTaskAssignedAtLocale;
  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;
  protected readonly TooltipAlignment = TooltipAlignment;
  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;

  private readonly tasksService = inject(TasksService);
  private readonly userService = inject(UserService);
  private readonly clipboard = inject(Clipboard);
  private readonly portalService = inject(CdkPortalService);
  private readonly injector = inject(Injector);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly translate = inject(TranslateService);
  private readonly taskAccessNoticeService = inject(TaskAccessService);
  private readonly destroyRef = inject(DestroyRef);

  get canChangeAssignee(): boolean {
    if (!this.canAssignToCurrentUser && !this.canReroute) return false;

    return (
      this.task.status !== TaskStatus.Closed &&
      this.task.status !== TaskStatus.Completed &&
      (this.task.taskRole === TaskRole.Assignee || this.task.taskRole === TaskRole.AssigneeCollaborator)
    );
  }

  get canAssignToCurrentUser(): boolean {
    return !this.task.acceptedBy;
  }

  get canReroute(): boolean {
    return this.task.acceptedBy?.id === this.userService.userProfile.id;
  }

  get canOpenTaskWizard(): boolean {
    return (
      this.task.status === TaskStatus.NotStarted ||
      this.task.status === TaskStatus.InProgress ||
      this.task.status === TaskStatus.Completed
    );
  }

  get canSendReminder(): boolean {
    return (
      (this.task.status === TaskStatus.NotStarted || this.task.status === TaskStatus.InProgress) &&
      this.task.taskRole === TaskRole.Assigner
    );
  }

  get canCloseTask(): boolean {
    return (
      this.task.status !== TaskStatus.Closed &&
      this.task.status !== TaskStatus.Completed &&
      this.task.taskRole === TaskRole.Assigner
    );
  }

  get usersWithAccessCount(): number {
    return R.uniqBy((performer) => performer.id, [this.task.acceptedBy, ...this.task.assigneeUsers].filter(Boolean))
      .length;
  }

  get unauthorizedCount(): number {
    return this.task.emailAccess.filter(({ access }) => access === TaskEmailAccessLevel.None).length;
  }

  ngOnInit(): void {
    this.tasksService
      .subscribeToTaskUpdates(this.task.id)
      .pipe(untilDestroyed(this))
      .subscribe((task: Task): void => {
        this.task = task;
        this.cdr.detectChanges();
      });
  }

  copyLink(): void {
    const link = `${ConfigService.settings.apiUrl}/tasks/task_id=${this.task.id}`;
    this.clipboard.copy(link);
    this.portalService.presentToast(this.translate.instant('common.strings.linkCopied'), ToastType.Info);
  }

  sendReminder(): void {
    this.tasksService.sendReminder(this.task.id);
  }

  async closeTask(): Promise<void> {
    const confirmed =
      (await presentConfirmationDialog(this.injector, {
        title: this.translate.instant('conversations-v4.tasks.closePrompt.title'),
        description: this.translate.instant('conversations-v4.tasks.closePrompt.message'),
        confirmActionText: this.translate.instant('conversations-v4.tasks.closePrompt.closeTask'),
        cancelActionText: this.translate.instant('common.buttons.cancel'),
        destructive: true,
        style: 'compact',
      })) === 'confirm';

    if (confirmed) this.tasksService.closeTask(this.task.id);
  }

  displayUnauthorizedRecipients(): void {
    this.taskAccessNoticeService
      .displayNotice(
        this.task.emailAccess.filter(({ access }) => access === TaskEmailAccessLevel.None).map(({ email }) => email),
      )
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }
}
