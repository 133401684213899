<div class="task-access-notice-dialog">
  <div class="task-access-notice-dialog__header">
    <div class="task-access-notice-dialog__header-title">
      {{ 'conversations-v4.tasks.taskAccessDialog.title' | translate }}
    </div>

    <div class="task-access-notice-dialog__header-description">
      {{ 'conversations-v4.tasks.taskAccessDialog.description' | translate }}
    </div>
  </div>

  <div class="task-access-notice-dialog__list">
    <div class="task-access-notice-dialog__list-title">
      {{ 'conversations-v4.tasks.taskAccessDialog.nonAuthorizedContacts' | translate }}
    </div>

    <div class="task-access-notice-dialog__list-items">
      @for (contact of dialogData.contacts; track $index) {
        @if (isFullContact(contact)) {
          <cc-dropdown-action [focusable]="false">
            <ng-container slot="leading">
              <cc-user-avatar
                [src]="contact.avatarUrl"
                [name]="contact.name"
                [id]="contact.id"
                [size]="20"
              ></cc-user-avatar>
            </ng-container>
            <ng-container slot="title">{{ contact.name }}</ng-container>
            <ng-container slot="caption">{{ contact.emails[0]?.email }}</ng-container>
            <ng-container slot="trailing">{{ contact.company.name }}</ng-container>
          </cc-dropdown-action>
        } @else {
          <cc-dropdown-action [focusable]="false">
            <ng-container slot="leading">
              <cc-user-avatar
                [src]="undefined"
                [name]="contact"
                [size]="20"
              ></cc-user-avatar>
            </ng-container>
            <ng-container slot="title">{{ contact }}</ng-container>
          </cc-dropdown-action>
        }
      }
    </div>
  </div>

  <div class="task-access-notice-dialog__footer">
    @if (dialogData.confirmButtonLabel; as confirmButtonLabel) {
      <app-button
        (click)="dialogRef.close(true)"
        [type]="ButtonType.Danger"
        [size]="ButtonSize.Small"
      >
        {{ confirmButtonLabel }}
      </app-button>
    }

    <app-button
      (click)="dialogRef.close(false)"
      [type]="dialogData.dismissButtonLabel ? ButtonType.Secondary : ButtonType.Primary"
      [size]="ButtonSize.Small"
    >
      {{ dialogData.dismissButtonLabel || 'common.buttons.ok' | translate }}
    </app-button>
  </div>
</div>
