export interface Contact {
  id: number;
  name: string;
  avatarUrl: string | undefined;
  emails: ContactEmail[];
  phoneNumbers: ContactPhoneNumber[];
  company: ContactCompany | undefined;
  source: ContactSourceType;
  type: ContactType;
}

export interface ContactEmail {
  email: string;
  type: string | undefined;
}

export interface ContactPhoneNumber {
  phoneNumber: string;
  type: string | undefined;
}

export interface ContactCompany {
  id: number;
  name: string;
  logoUrl: string | undefined;
  title: string | undefined;
}

export enum ContactSourceType {
  Inbox = 'inbox',
  AddressBook = 'address_book',
  CloverUser = 'LS',
}

export enum ContactType {
  CloverUser = 'InternalUser',
  ExternalContact = 'ExternalContact',
}
