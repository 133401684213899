import { DIALOG_DATA, DialogModule, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import type { Contact } from '@clover/conversations-v4/conversation/state/contacts/contacts.model';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';

import { ButtonComponent } from '../../../../stories/buttons/button/button.component';
import { UserAvatarComponent } from '../../../../stories/misc/user-avatar/user-logo.component';
import { DropdownActionComponent } from '../../../../stories/overlays/dropdown/dropdown-action/dropdown-action.component';

export interface TaskAccessNoticeDialogData {
  contacts: Array<Contact | string>;
  dismissButtonLabel?: string | undefined;
  confirmButtonLabel?: string | undefined;
}

export type TaskAccessNoticeDialogResult = boolean;

@Component({
  selector: 'cc-task-access-notice-dialog',
  standalone: true,
  imports: [TranslateModule, DropdownActionComponent, ButtonComponent, UserAvatarComponent, DialogModule],
  templateUrl: './task-access-notice-dialog.component.html',
  styleUrl: './task-access-notice-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskAccessNoticeDialogComponent {
  readonly dialogData: TaskAccessNoticeDialogData = inject(DIALOG_DATA);

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;

  protected readonly dialogRef: DialogRef<TaskAccessNoticeDialogResult> = inject(DialogRef);

  protected isFullContact(contact: Contact | string): contact is Contact {
    return (contact as Contact).id !== undefined;
  }
}
