<div
  #assigneeDropdownTrigger
  (click)="type === 'assignee' && canChangeAssignee && (dropdownVisible = !dropdownVisible)"
  [attr.aria-expanded]="dropdownVisible"
  [attr.role]="type === 'assignee' && canChangeAssignee ? 'button' : 'presentation'"
  [class.task-performer-select--disabled]="type !== 'assignee' || !canChangeAssignee"
  class="task-performer-select"
>
  <div class="task-performer-select__avatar-stack">
    <img
      [alt]="performerCompany.name"
      [ccTooltip]="performerCompany.name"
      [src]="performerCompany.logoUrl || 'assets/svg/common/new-company-placeholder.svg'"
      height="48px"
      width="48px"
    />

    @if (performer) {
      <img
        [alt]="performer.name"
        [ccTooltip]="performer.firstName + ' ' + performer.lastName"
        [src]="performerAvatarUrl"
        height="48px"
        width="48px"
      />
    } @else {
      <div
        [ccTooltip]="'conversations-v4.tasks.noAssignee' | translate"
        class="task-performer-select__avatar-stack-placeholder"
      ></div>
    }
  </div>

  <div class="task-performer-select__title">
    @if (performer) {
      {{ performer.firstName }} {{ performer.lastName }}
    } @else {
      @switch (type) {
        @case ('assignee') {
          {{ 'conversations-v4.tasks.noAssignee' | translate }}
        }
        @case ('assigner') {
          {{ 'conversations-v4.tasks.noAssigner' | translate }}
        }
      }
    }
  </div>

  <i
    [class.icon-collapse]="dropdownVisible"
    [class.icon-expand]="!dropdownVisible"
    class="task-performer-select__toggle"
  ></i>
</div>

<ng-template
  (detach)="dropdownVisible = false"
  (overlayOutsideClick)="dropdownVisible = getOverlayVisibilityAfterOutsideClick($event, assigneeDropdownTrigger)"
  [cdkConnectedOverlayOpen]="dropdownVisible"
  [cdkConnectedOverlayOrigin]="assigneeDropdownTrigger"
  [cdkConnectedOverlayPositions]="dropdownPositionStrategy"
  cdkConnectedOverlay
>
  <cc-dropdown width="200px">
    @if (canAssignToCurrentUser) {
      <cc-dropdown-action
        (click)="assignToCurrentUser(); dropdownVisible = false"
        leadingIcon="icon-user"
      >
        <ng-container slot="title">
          {{ 'conversations-v4.tasks.assignToMe' | translate }}
        </ng-container>
      </cc-dropdown-action>
    }

    @if (canReroute) {
      <cc-dropdown-action
        (click)="reroute(); dropdownVisible = false"
        leadingIcon="icon-re-route"
      >
        <ng-container slot="title">
          {{ 'conversations-v4.tasks.reRouteTask' | translate }}
        </ng-container>
      </cc-dropdown-action>
    }
  </cc-dropdown>
</ng-template>
